'use client'

import { LucideIconName } from '~/core/ui/IconWrapper'
import { FC } from 'react'
import { EmptyDataIcon, EmptySearchIcon } from '~/core/ui/FillIcons'
import { TextButton } from '~/core/ui/TextButton'
import { cn } from './utils'

const Empty: FC<{
  type?: 'empty-data' | 'empty-search'
  title?: string
  description?: string
  buttonTitle?: string
  buttonClassNameText?: string
  buttonIcon?: LucideIconName
  isShowIcon?: boolean
  onClick?: (event: React.MouseEvent<HTMLElement>) => void
  className?:string
  classNameIcon?: string
  classNameTitleWrapper?: string
}> = (props) => {
  const {
    type = 'empty-data',
    title = '',
    description = '',
    buttonTitle,
    buttonClassNameText = '',
    isShowIcon = true,
    buttonIcon,
    onClick,
    className='max-w-[400px]',
    classNameIcon = '',
    classNameTitleWrapper = 'mt-4'
  } = props
  return (
    <div className={cn('m-auto flex flex-col items-center justify-center', className)}>
      {isShowIcon && (
        <div className={cn('flex h-[60px] min-h-[60px] w-[60px] min-w-[60px] items-center justify-center tablet:h-[80px] tablet:min-h-[80px] tablet:w-[80px] tablet:min-w-[80px]', classNameIcon)}>
          {type === 'empty-search' ? <EmptySearchIcon /> : <EmptyDataIcon />}
        </div>
      )}

      <div className={`mb-1 ${isShowIcon ? classNameTitleWrapper : ''}`}>
        <h3
          className={cn('text-center font-medium text-gray-900 dark:text-gray-300', isShowIcon ? 'text-base' : 'text-lg')}>
          {title}
        </h3>
      </div>

      <div className={buttonTitle ? 'mb-2' : ''}>
        <p
          className={`text-center ${
            isShowIcon ? 'text-sm' : 'text-base'
          } text-gray-600 dark:text-gray-400`}>
          {description}
        </p>
      </div>

      {buttonTitle ? (
        <TextButton
          size={isShowIcon ? 'md' : 'lg'}
          onClick={(e) => onClick && onClick(e)}
          label={buttonTitle}
          classNameText={`${buttonClassNameText} font-medium`}
          iconMenus={buttonIcon}
          underline={false}
        />
      ) : null}
    </div>
  )
}

export default Empty
