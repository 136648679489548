import * as pdfjs from 'pdfjs-dist'
import { useEffect, useRef, useState } from 'react'
import configuration from '~/configuration'
import useMiddlewareRequest from '~/core/middleware/use-middleware-request'
import IconWrapper from '~/core/ui/IconWrapper'
import { Tooltip } from '~/core/ui/Tooltip'
import { catchErrorFromRequest } from '~/core/utilities/catch-api-error'

//@ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'
import { cn } from '~/core/ui/utils'
import { useTranslation } from 'react-i18next'
import useBoundStore from '~/lib/store'
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker

export const PDF_TYPE = 'application/pdf'
export const DOC_TYPE = 'application/msword'
export const DOCX_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'

const AttachmentView = ({
  item
}: {
  item?: {
    id: string
    file: string
    blobs: { content_type: string; size: number; filename: string }
  }
}) => {
  const { t } = useTranslation()
  const { setToast } = useBoundStore()
  const [randomReload, setRandomReload] = useState(0)
  const [storeFileLocal, setFileLocal] = useState('')
  const { trigger, data, isMutating } = useMiddlewareRequest({
    endpoint: configuration.api.downloadFile,
    method: 'POST'
  })
  const attachmentViewRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const fetchAPI = async (attachmentId: number) => {
      try {
        await trigger({ attachment_id: attachmentId })
      } catch (error) {
        catchErrorFromRequest({ error, setToast })
      }
    }

    // Why not use item?.id
    // Because we need to cover case update file (not change id)
    if (item?.blobs?.filename && item?.blobs?.filename !== storeFileLocal) {
      setFileLocal(item?.blobs?.filename)
      fetchAPI(Number(item.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item?.file])

  useEffect(() => {
    if (data) {
      switch (item?.blobs.content_type) {
        case PDF_TYPE:
          renderPdf(data)
          break

        case DOC_TYPE:
          renderDoc(data)
          break

        default:
          renderDocx(data)
          break
      }
    }
  }, [data, item?.blobs.content_type])

  const renderPdf = async (data: Blob) => {
    let blob = await new Blob([data], { type: 'application/pdf' })
    let url = await window.URL.createObjectURL(blob)

    if (attachmentViewRef?.current) {
      const loadingTask = pdfjs.getDocument(url)
      const pdfDoc = await loadingTask.promise

      for (
        let pageNumber = 1;
        pageNumber <= pdfDoc._pdfInfo.numPages;
        pageNumber++
      ) {
        let canvas: HTMLCanvasElement = document.createElement('canvas')
        attachmentViewRef.current.appendChild(canvas)
        const page = await pdfDoc.getPage(pageNumber)
        const viewport = page.getViewport({ scale: 2 })

        canvas.height = viewport.height
        canvas.width = viewport.width

        setTimeout(() => {
          page.render({
            canvasContext: canvas.getContext('2d') as CanvasRenderingContext2D,
            viewport: viewport
          })
        }, 0)
      }
    }
  }

  const renderDoc = async (data: Blob) => {}

  const renderDocx = async (data: Blob) => {
    const { renderAsync } = await import('docx-preview')
    var arrayBuffer
    var fileReader = new FileReader()
    fileReader.onload = function (event) {
      arrayBuffer = event?.target?.result

      if (attachmentViewRef?.current) {
        renderAsync(arrayBuffer, attachmentViewRef.current, undefined, {
          ignoreWidth: true,
          ignoreHeight: true
        })
      }
    }
    fileReader.readAsArrayBuffer(data)
  }

  if (!item) return null

  const link = `https://docs.google.com/viewerng/viewer?toolbar=0&url=${item?.file.replace(
    /&/g,
    '%26'
  )}`
  const iframeLink = `https://docs.google.com/viewer?embedded=true&toolbar=0&url=${item?.file.replace(
    /&/g,
    '%26'
  )}`

  return (
    <div className="relative flex h-full min-h-[64px] flex-col items-end bg-gray-200">
      {item?.file && item?.blobs.content_type !== DOC_TYPE ? (
        <Tooltip
          classNameAsChild="sticky right-0 top-0 z-[12]"
          content={t('tooltip:viewFile')}>
          <a
            href={link}
            target="_blank"
            className="flex h-8 w-8 items-center justify-center bg-gray-900 shadow-sm">
            <IconWrapper name="ExternalLink" className="text-white" />
          </a>
        </Tooltip>
      ) : null}

      {isMutating ? (
        <div className="-mt-8 flex w-full flex-1 flex-col items-center justify-center">
          <IconWrapper
            className="animate-spin text-gray-600 dark:text-gray-400"
            name="Loader2"
            size={32}
          />
        </div>
      ) : (
        <>
          {item?.blobs.content_type === DOC_TYPE ? (
            <>
              <button
                type="button"
                onClick={() => setRandomReload(randomReload + 1)}>
                <Tooltip
                  classNameAsChild="absolute left-0 top-0 z-[12]"
                  content={t('tooltip:refreshFile')}>
                  <div className="flex h-8 w-8 items-center justify-center bg-gray-900 shadow-sm">
                    <IconWrapper name="RefreshCw" className="text-white" />
                  </div>
                </Tooltip>
              </button>
              <iframe
                key={randomReload}
                src={iframeLink}
                frameBorder="no"
                style={{
                  width: '100%',
                  height: '100vh'
                }}
              />
            </>
          ) : (
            <div
              ref={attachmentViewRef}
              id="attachment-view"
              className={cn(
                '-mt-8 min-h-full w-full p-3',
                item?.blobs.content_type === PDF_TYPE
                  ? 'flex flex-1 flex-col items-center justify-center space-y-3 [&>canvas]:w-full [&>canvas]:max-w-[800px]'
                  : 'grid overflow-scroll [&>.docx-wrapper>section.docx:last-child]:mb-0 [&>.docx-wrapper>section.docx]:mb-3 [&>.docx-wrapper>section.docx]:w-full [&>.docx-wrapper>section.docx]:max-w-[800px] [&>.docx-wrapper>section.docx]:!p-5 [&>.docx-wrapper>section.docx]:shadow-none [&>.docx-wrapper]:bg-transparent [&>.docx-wrapper]:p-0 [&_table]:!w-full [&_table]:max-w-full'
              )}
            />
          )}
        </>
      )}
    </div>
  )
}

export default AttachmentView
